import { InformationIcon } from "@biblioteksentralen/icons";
import { CloseButton, colors, Flex, Heading, Icon, Stack, Text, ToastProps, useToast } from "@biblioteksentralen/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { usePrevious } from "react-use";
import { useTranslation } from "src/utils/useTranslation";
import { useUser } from "../../utils/useUser";

export const velkomstQueryParam = `status=velkommen`;

export const useVelkommenTilForrigebokToast = () => {
  const toast = useToast();
  const router = useRouter();
  const user = useUser();

  const showToast = router.asPath.includes(velkomstQueryParam) && user.onBoardingFinished;
  const prevShowToast = usePrevious(showToast);

  useEffect(() => {
    if (showToast && !prevShowToast) {
      toast({
        render: Toast,
        duration: 30000,
      });
    }
  }, [showToast, prevShowToast, toast]);
};

const Toast = (props: ToastProps) => {
  const { t } = useTranslation();

  return (
    <Flex backgroundColor={colors.neptune[600]} color="white" padding="1em" borderRadius="lg" gap=".75em">
      <Icon as={InformationIcon} fontSize="1.25em" />
      <Stack spacing=".25em">
        <Heading size="sm" fontWeight={800}>
          {t("Velkommen til Forrigebok!")}
        </Heading>
        <Text>{t("Du har laget en konto og har nå tilgang til å registrere bøker i Forrigebok.")}</Text>
      </Stack>
      <CloseButton transform="translate(.75em, -.75em)" size="sm" onClick={props.onClose} />
    </Flex>
  );
};
