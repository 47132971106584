import { BsSearch } from "@biblioteksentralen/icons";
import { Box, BoxProps, Button, colors, InputGroup, InputLeftElement } from "@biblioteksentralen/react";
import { Input } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useRouter } from "next/dist/client/router";
import React, { useEffect, useRef, useState } from "react";
import { useMount } from "react-use";
import { useTranslation } from "src/utils/useTranslation";

const StyledForm = styled.form`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;

function SearchInput({ ...chakraProps }: BoxProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState("");
  const { push, query, pathname } = useRouter();
  const urlQuery = typeof query.q === "string" ? query.q : undefined;
  const timeoutRef = useRef<number | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Prepoulerer søkefelt hvis det ligger et søk i url
    if (urlQuery) {
      setValue(urlQuery);
    }
  }, [urlQuery]);

  useMount(() => {
    // Sett fokus på søkefelt hvis vi er på forsiden
    if (pathname === "/") {
      inputRef.current?.focus();
    }
    // Setter/beholder fokus på søkefelt hvis man nettop har gjort et søk på forsiden
    if (urlQuery && urlQuery !== value) {
      inputRef.current?.focus();
    }
  });

  const resetTimeout = () => timeoutRef.current && window.clearTimeout(timeoutRef.current);

  const search = (value: string) => {
    // Viktig å passe på at search-input ikke gjør 'dobbelt søk', som bla førte til at man ble navigert tilbake til søke-treff side etter at man egentlig har trykket seg videre pga at timeouten tar ett sekund. Førte til flaky e2e-tester.
    resetTimeout();
    value && push(`/search?q=${value}`);
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    search(value);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
    resetTimeout();
    // Søker automatisk etter 1 sekund.
    timeoutRef.current = window.setTimeout(() => search(value), 1000);
  };

  const label = t("Søk på ISBN, tittel, forfatter eller appellterm");

  return (
    <Box {...chakraProps}>
      <StyledForm role="search" onSubmit={handleSubmit}>
        <InputGroup>
          <InputLeftElement pointerEvents="none" color="gray.400" aria-hidden>
            <BsSearch />
          </InputLeftElement>
          <Input
            backgroundColor="white"
            color="gray.900"
            boxShadow="md"
            borderRightRadius={0}
            _hover={{
              backgroundColor: "gray.100",
            }}
            _focusVisible={{
              backgroundColor: "white",
              outline: "outline",
              boxShadow: "var(--chakra-shadows-outline)",
            }}
            type="search"
            placeholder={label}
            value={value}
            onChange={handleInput}
            ref={inputRef}
            minW={{ base: "14rem", sm: "17rem" }}
          />
        </InputGroup>
        <Button
          backgroundColor={colors.neptune[300]}
          _hover={{ backgroundColor: colors.neptune[400] }}
          borderLeftRadius={0}
          type="submit"
          variant="solid"
          color={colors.neptune["900"]}
        >
          {t("Søk")}
        </Button>
      </StyledForm>
    </Box>
  );
}

export default SearchInput;
