import { BiblioteksentralenLogo } from "@biblioteksentralen/icons";
import { Box, colors, Container, Flex, HStack, Link, Stack } from "@biblioteksentralen/react";
import { jsonFetcher } from "@forrigebok/common";
import { Infoside } from "@forrigebok/generatedtypes";
import NextLink from "next/link";
import { useTranslation } from "src/utils/useTranslation";
import useSWR from "swr";
import { HideOnPrint } from "../../HideOnPrint";

function Footer() {
  const infosider = useSWR<Infoside[]>("/api/internal/infosider", jsonFetcher);
  const { t, ts } = useTranslation();

  return (
    <HideOnPrint as="footer" role="contentinfo" background={colors.grey15} fontWeight="600">
      <Container maxW="container.xl">
        <Flex
          paddingTop={{ base: "2rem", md: "3.5rem" }}
          paddingBottom={{ base: "1.5rem", md: "3.5rem" }}
          gap={{ md: "1rem 4rem" }}
          flexDirection={{ base: "column", md: "row" }}
          alignItems="baseline"
          flexWrap="wrap"
        >
          <Link href="https://www.bibsent.no/" variant="plain" marginBottom={{ base: "2rem", md: 0 }}>
            <HStack>
              <BiblioteksentralenLogo fontSize="1.5rem" />
              <Stack spacing={0} lineHeight={1.2} as="p">
                <Box as="span" fontSize="xs" fontWeight={400}>
                  {t("Utviklet av")}
                </Box>
                <Box as="span">Biblioteksentralen</Box>
              </Stack>
            </HStack>
          </Link>
          <Link as={NextLink} href={`/vokabular`} variant="plain">
            Vokabular
          </Link>
          {infosider.data?.map((side) => (
            <Link key={side._id} as={NextLink} href={`/info/${side.slug?.current}`} variant="plain">
              {ts(side.title)}
            </Link>
          ))}
          <Link as={NextLink} href={`/nyheter`} variant="plain">
            {t("Nyheter")}
          </Link>
          <Link variant="plain" href="https://nestebok.no">
            Nestebok
          </Link>
          <Link as={NextLink} href={`/api`} variant="plain">
            API
          </Link>
          <Link variant="plain" fontSize="sm" href={"/cms"} marginLeft="auto" marginTop={{ base: "2rem", md: 0 }}>
            Admin
          </Link>
        </Flex>
      </Container>
    </HideOnPrint>
  );
}

export default Footer;
